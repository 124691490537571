import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from 'react-bootstrap';

import * as yup from 'yup';
import { Formik, useFormik } from 'formik';


import moment from 'moment';
import API from '../../common/API';

import { v4 as uuidv4 } from 'uuid';
import { parsedUser } from '../../common/GetCurrentUser';


//Validation
const validationSchema = yup.object({
    //   id: yup.string().required(),
    amount: yup.number().required(),
    previousAmount: yup.number().required(),
    date: yup.string().required(),

});
//Component
const AddEditMetterHistory = props => {
    const [state, setState] = useState({
        UI: {
            Validated: false
        },

    })

    const user = parsedUser();

    const handleSubmit = async (obj, event) => {
        console.log("values to submit", obj);

        if (obj.id.length === 0) {
            obj.id = uuidv4();
            obj.created = moment().format("YYYY-MM-DD HH:mm:ss")
            obj.createdid = user.id;

            
        }
        
        if (obj.identifier === null) {
            obj.identifier = uuidv4();
        }


        let request = await API.postAction(`metters/history/${props.mode}`, obj);


        if (request.status === 200) {
            props.toggle(null, props.mode, true);
        }





    };
    const formik = useFormik({
        initialValues: props.model,
        validationSchema,
        onSubmit: handleSubmit,

    });

    useEffect(() => {
        setTimeout(() => {
            var focus = document.getElementsByName("name")[0];
            if (focus) {
                focus.focus();
            }
        }, 500)
    }, [])
    if (!props.model) {
        return (<></>)
    }
    return (
        <Modal show={props.show} onHide={props.toggle} size='md' className='modalRight'>
            <Modal.Header closeButton>
                <Modal.Title>Historico del Medidor</Modal.Title>

            </Modal.Header>

            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {/* 
                LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING
                {Object.values(formik.errors).map((item, index)=>{
                    return(item);
                })} */}
                    <Row className='mx-0'>

                        <Col xs={6} >
                            <Form.Label>Fecha</Form.Label>
                            <input
                                type="datetime-local"
                                className='form-control'
                                name="date"
                                value={formik.values.date ? moment(formik.values.date).format("YYYY-MM-DDTHH:MM:SS"): ""} onChange={formik.handleChange} />
                            {formik.errors.date ? <div className="invalid text-sm">{formik.errors.date}</div> : null}
                        </Col>
                        <Col xs={6} >
                            <Form.Label>Monto</Form.Label>
                            <input type="number"
                                step={0.01}
                                className='form-control'
                                name="amount"
                                value={formik.values.amount ?? ""} onChange={formik.handleChange} />
                            {formik.errors.amount ? <div className="invalid text-sm">{formik.errors.amount}</div> : null}
                        </Col>
                        <Col xs={6} className="mt-2" >
                            <Form.Label>Fecha Anterior</Form.Label>
                            <input
                                type="datetime-local"
                                className='form-control'
                                name="previousDate"
                                value={formik.values.previousDate ?? ""} onChange={formik.handleChange} />
                            {formik.errors.previousDate ? <div className="invalid text-sm">{formik.errors.previousDate}</div> : null}
                        </Col>
                        <Col xs={6} className="mt-2" >
                            <Form.Label>Monto Anterior</Form.Label>
                            <input type="number"
                                step={0.01}
                                className='form-control'
                                name="previousAmount"
                                value={formik.values.previousAmount ?? ""} onChange={formik.handleChange} />
                            {formik.errors.previousAmount ? <div className="invalid text-sm">{formik.errors.previousAmount}</div> : null}
                        </Col>
                        <Col xs={12} >
                            <Form.Label>Nota</Form.Label>
                            <textarea className='form-control' name="note"
                                value={formik.values.notes} onChange={formik.handleChange} ></textarea>
                            {formik.errors.notes ? <div className="invalid text-sm">{formik.errors.notes}</div> : null}
                        </Col>


                    </Row>

                </Modal.Body>
                <Modal.Footer className='p-1'>
                    <Button variant="primary" type="submit" className="btn-sm"
                        disabled={state.UI.isLoading}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    )
}

export default AddEditMetterHistory;