
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Navbar, Dropdown, Container, ListGroup, InputGroup } from 'react-bootstrap';

import NOTIFICATIONS_DATA from "../../data/notifications";
import { Routes } from "../../routes";

import { userAvatar } from "../../common/helpers";
import { parsedUser } from "../../common/GetCurrentUser";




export default (props) => {
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);

  const user = parsedUser();
  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };


  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <>
      <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 border-bottom bg-white mb-4">
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <Form className="navbar-search">
                <Form.Group id="topbarSearch">
                  <InputGroup className="input-group-merge search-bar d-none">
                    <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Search" />
                  </InputGroup>
                </Form.Group>
              </Form>
            </div>
            <Nav className="align-items-center">


              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <img src={userAvatar(user && user.Email, 40)} className="user-avatar rounded" alt="User" />
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">{user && user.Email}</span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item className="fw-bold" onClick={() => props.history.push(Routes.Profile.path)}>
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> Perfil
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" onClick={() => props.history.push(Routes.Settings.path)}>
                    <FontAwesomeIcon icon={faCog} className="me-2" /> Configuración
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold d-none">
                    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold" as="button" onClick={() => {
                    //  setShowAddEditProject(true);
                  }}>
                    <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item className="fw-bold"
                    onClick={() => {
                      props.history.push(Routes.SignIn.path)
                    }}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Cerrar Sesión
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
