import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FormatMoney, NumberFormat } from "../../common/helpers";

import API from '../../common/API';
import { debounce } from 'lodash';

import { parsedUser } from "../../common/GetCurrentUser";

const QuickAddEditMetter = props => {
    const user = parsedUser();
    let totalVolFinal = 0;
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState([])
    const bindRemote = async () => {

        let request = await API.getAction(`tank/byDate?organization=${user.OrganizationId}&date=${props.date}`);

        if (request.data.status === "ok") {
            setRecords(request.data.response);

            props.SumMetter(request.data.response);
        }
    }

    const handleSubmitBatch = async (obj) => {
        try {
            console.log(records);
            setIsLoading(true);
            let request = await API.postAction(`tank/historyAddEdit`, records);
            setIsLoading(false);
            console.log("response from adding", request);
            if (request.status === 200) {
                await bindRemote();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const searchVolume = debounce(async (idTank, percentage, field) => {

        let request = await API.getAction(`tank/CalibrationTableSearchVolume?idTank=${idTank}&percentage=${percentage}`);

        if (request.data.status === "ok") {
            let obj = request.data.response;

            if (obj === null) {
                return;
            }
            let r = records;
            r.forEach((item, index) => {

                if (item.tankId === idTank) {

                    if (field === 'previousPercentage') {
                        item.previousAmount = obj.volume;
                    }
                    if (field === 'percentage') {
                        item.amount = obj.volume;
                    }
                }
                r[index] = item;
            });
            setRecords(r);

        }

    }, 500)

    const handleChange = (index, element) => {
        const newCollection = records.map((item, indexElement) => {
            if (indexElement === index) {
                return element;
            } else {
                return item;
            }
        })

        setRecords(newCollection);
    }

    useEffect(() => {

        bindRemote();
    }, [props.date])

    useEffect(() => {
        console.log("records update", records);
    }, [records])

    let totalSummary = 0;
    return (<>
        <Card className="shadow-sm my-4 bg-white">
            <Card.Header className="p-1">
                <Card.Title>Tanque</Card.Title>
            </Card.Header>
            <Card.Body className="p-1">
                <table className="table table-striped border">
                    <thead>
                        <tr>
                            <td></td>
                            <th className="bg-primary text-white text-center" colSpan="2">Porcentaje</th>
                            <th className="bg-secondary text-dark text-center" colSpan="4">Volumen</th>
                            <th></th>
                        </tr>
                        <tr>
                            {/* <th>
                                Tanque
                            </th> */}
                            <th>Tanque</th>
                            <th className="text-center bg-primary text-white">% Inicial</th>
                            <th className="text-center bg-primary text-white">% Final</th>
                            <th className=" text-center bg-secondary text-dark">Vol. Inicial</th>
                            <th className=" text-center bg-secondary text-dark">Llenado</th>
                            <th className=" text-center bg-secondary text-dark">Transferido</th>
                            <th className=" text-center bg-secondary text-dark">Vol. Final</th>
                            <th>Variación</th>

                        </tr>
                    </thead>
                    <tbody>
                        {records && Array.isArray(records) && records.map((item, index) => {
                            console.log(item);
                            let record = item;
                            let amount = (record.transfer * 1 + record.amount * 1) - ((record.previousAmount * 1) + (record.purchase * 1));
                            totalSummary += amount * 1;
                            totalVolFinal += record.amount;
                            console.log(record.amount, record.previousAmount, record.purchase, amount);
                            return (<tr key={index}>
                                {/* <td>30,000 gl</td> */}
                                <td colSpan={record.tankId === 'bolitas' ? 3 : record.tankId === 'mueble' ? 3 : 1}>
                                    {record.tankName}
                                    {record.tankId !== 'bolitas' && record.tankId !== "mueble" ? <>
                                        <br />
                                        <progress id={"file" + index} value={record.percentage * 1} max="100">  {record.percentage}% </progress>
                                    </> : null}
                                </td>
                                {(record.tankId !== 'bolitas' &&
                                    record.tankId !== 'mueble') &&
                                    <td className="text-center bg-primary px-1"><input type="number"
                                        name="previousPercentage"
                                        className="form-control"
                                        onChange={e => {

                                            record.previousPercentage = e.target.value * 1;
                                            // save the record to the row it belong
                                            setRecords(
                                                [...records.slice(0, index), record, ...records.slice(index + 1)]
                                            )
                                            //handleChange(index, record);
                                            searchVolume(record.tankId, e.target.value, "previousPercentage");
                                        }}
                                        onKeyUp={e => {


                                        }}
                                        step={0.01}
                                        min={0}
                                        value={record.previousPercentage ?? ""}
                                    ></input></td>
                                }
                                {(record.tankId !== 'bolitas' &&
                                    record.tankId !== 'mueble') &&
                                    <td className="text-center bg-primary px-1">
                                        <input type="number"
                                            className="form-control"
                                            name="percentage"
                                            onChange={e => {
                                                record.percentage = e.target.value * 1;
                                                //handleChange(index, record);
                                                setRecords(
                                                    [...records.slice(0, index), record, ...records.slice(index + 1)]
                                                )
                                                searchVolume(record.tankId, e.target.value, "percentage");
                                            }}
                                            onKeyUp={e => {


                                            }}
                                            step={0.01}
                                            min={0}
                                            value={record.percentage ?? ""}
                                        ></input></td>
                                }
                                {
                                    <td className="text-center bg-secondary px-1">
                                        <input type="number"
                                            className="form-control"
                                            name="previousAmount"
                                            onChange={e => {
                                                record.previousAmount = e.target.value * 1;
                                                //   handleChange(index, record);
                                                setRecords(
                                                    [...records.slice(0, index), record, ...records.slice(index + 1)]
                                                )

                                            }}
                                            step={0.01}
                                            min={0}
                                            value={record.previousAmount ?? ""}
                                        ></input></td>
                                }
                                {(record.tankId !== 'bolitas') &&
                                    <td className="text-center  px-1" style={{ backgroundColor: "blue" }}>
                                        {(record.tankId === '2' || record.tankId === '1') &&
                                            <input type="number" className="form-control"
                                                onChange={e => {
                                                    record.purchase = e.target.value * 1;
                                                    //  handleChange(index, record);
                                                    setRecords(
                                                        [...records.slice(0, index), record, ...records.slice(index + 1)]
                                                    )
                                                }}
                                                step={0.01}
                                                min={0}
                                                value={record.purchase ?? ""}></input>
                                        }
                                    </td>
                                }
                                {(record.tankId === 'bolitas') && <td colSpan={2}></td>}
                                {(record.tankId !== 'bolitas') &&
                                    <td className="text-center  px-1" style={{ backgroundColor: "blue" }}>
                                        {(record.tankId === '2') &&
                                            <input type="number" className="form-control"
                                                onChange={e => {
                                                    record.transfer = e.target.value * 1;
                                                    //  handleChange(index, record);
                                                    setRecords(
                                                        [...records.slice(0, index), record, ...records.slice(index + 1)]
                                                    )
                                                }}
                                                step={0.01}

                                                min={0}
                                                value={record.transfer ?? ""}></input>
                                        }
                                    </td>
                                }

                                <td className="text-center bg-secondary px-1">
                                    <input type="number" className="form-control"
                                        onChange={e => {
                                            record.amount = e.target.value * 1;
                                            //handleChange(index, record);
                                            setRecords(
                                                [...records.slice(0, index), record, ...records.slice(index + 1)]
                                            )
                                        }}
                                        step={0.01}
                                        min={0}
                                        value={record.amount ?? ""}></input>
                                </td>


                                <td><label>{FormatMoney(amount)} gl</label></td>

                            </tr>)
                        })}

                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={7}>

                            </th>
                            <th><h4>{NumberFormat(totalVolFinal)} gl</h4></th>
                        </tr>
                    </tfoot>
                </table>
            </Card.Body>
            <Card.Footer className="p-1">
                <Row>
                    <Col className="text-uppercase">
                        Total movimiento: <b>{NumberFormat(totalSummary)} gl</b></Col>
                    <Col className="text-end">
                        <button className="btn btn-primary btn-sm"
                            type="button"
                            disabled={isLoading}
                            onClick={() => handleSubmitBatch(null)}>
                            {isLoading ? <i className="fa fa-spinner fa-spin me-2"></i> : null}
                            Guardar</button>
                    </Col>
                </Row>

            </Card.Footer>
        </Card >
    </>);
}

export default QuickAddEditMetter;