//We are settings an initial state here for clarity, probably is not needed

const Settings = {
    App: "Zaboka",
    Name: "zaboka",
    debug: false,
    GoogleMapsAPIKey: "AIzaSyBYmH6z_csu0XTQ8JO5ZK1iquSzk6LrKpU",
    baseURl: "./",
    BasePathForAPI: "https://api.zaboka.com.ht/api/",
    // BasePathForAPI: "https://localhost:7193/api/",


    EventStatus: [
        {
            type: "ignitionOn",
            text: "Dispositivo encendido"
        },
        { type: "ignitionOff", text: "Dispositivo apagado" },
        { type: "geofenceEnter", text: "Ingreso en la geocerca" },
        { type: "geofenceExit", text: "Salio de la geocerca" },
        { type: "deviceStopped", text: "Dispositivo detenido" },
        { type: "deviceMoving", text: "Dispositivo en movimiento" },
        { type: "deviceOnline", text: "En Linea" },
        { type: "deviceOffline", text: "Fuera de Linea" },

        { type: "alarm", text: "Alarma" },
    ]


};

export default Settings;
