
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import API from '../../common/API';
import { parsedUser } from '../../common/GetCurrentUser';

import { Routes } from '../../routes';
import AddEditMetter from './AddEditMetter';
import emptyImg from "../../assets/img/illustrations/no_content.svg";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Pagination from '../Common/Pagination';
import { FormatMoney } from '../../common/helpers';

import { v4 as uuidv4 } from 'uuid';
import AddEditMetterHistory from './AddEditMetterHistory';
import TotalBilledMonthly from '../dashboard/TotalBilledMonthly';
import MetterHistory from './MetterHistory';

const MetterList = props => {
    const history = useHistory();
    const [Search, setSearch] = useState("");
    const [state, setState] = useState({
        Records: [],
        UI: {
            IsLoading: false,
            showAddEdit: false,
            Recordselected: null
        },
        currentPage: 0,
        Quantity: 20,
        TotalPages: 0,
        OnlyPendingToPaid: false
    })
    const theUser = parsedUser();
    useEffect(() => {
        if (theUser === null) {
            window.location = Routes.SignIn.path;
        }
        request();
    }, [state.currentPage]);


    const onPageChanged = async obj => {
        setState({
            ...state,
            currentPage: obj
        });


        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const request = async () => {

        let Query = "&Search=" + Search + "&Quantity=" + state.Quantity + "&Page=" + state.currentPage + "&OnlyPendingToPaid=" + state.OnlyPendingToPaid;

        //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
        let requestAPI = await API.getAction(`Metters/` + theUser.organizationId, Query);

        if (requestAPI.data.status === "ok") {
            setState({
                ...state,
                Records: requestAPI.data.response,
                //   Quantity: requestAPI.data.quantityOfRecords,
                //     Page: requestAPI.data.page,
                TotalPages: requestAPI.data.quantityOfPages

            })
        }
    };

    const [showAddEdit, setShowAddEdit] = useState(false);
    const [showAddEditHistory, setShowAddEditHistory] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemHistory, setSelectedItemHistory] = useState(null);

    const toggleAddEdit = async (obj, mode, reload) => {
        setMode(mode);
        setSelectedItem(obj);
        setShowAddEdit(!showAddEdit);
        if (reload) {
            await request();
        }
    }

    const toggleAddEditHistory = async (obj, mode, reload) => {
        setMode(mode);
        setSelectedItemHistory(obj);
        setShowAddEditHistory(!showAddEditHistory);
        if (reload) {
            await request();
        }
    }
    const [showMetterHistory, setShowMetterHistory] = useState(false);
    const toggleMetterHistory = (obj) => {
        setSelectedItem(obj);
        setShowMetterHistory(!showMetterHistory);
    }

    const deleteConfirm = async (obj) => {

        let currentUser = parsedUser();

        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete ' + obj.name + "?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await API.getAction("Metters/delete/" + obj.id + "?UserId=" + currentUser.ID);
                        await request();
                    }
                },
                {
                    label: 'No',
                    //     onClick: () => alert('Click No')
                }
            ]
        });
    }
    const [mode, setMode] = useState("add");
    return (<>
        {showMetterHistory ?
            <MetterHistory toggle={toggleMetterHistory} show={showMetterHistory} model={selectedItem} /> : null}
        {showAddEdit ?
            <AddEditMetter toggle={toggleAddEdit} show={showAddEdit} model={selectedItem} mode={mode} /> : null}

        {showAddEditHistory ?
            <AddEditMetterHistory toggle={toggleAddEditHistory} show={showAddEditHistory} model={selectedItemHistory} mode={mode} /> : null}


        <div className="row mb-4 mx-0">
            <div className="col ">
                <h5 className="display-4">
                    <i className="fas fa-gas-pump"></i>&nbsp;Medidores</h5>
            </div>
            <div className="ml-auto col-auto text-right">
                <div className='input-group'>
                    <div className='input-group-text'>
                        <i className='fa fa-search'></i>
                    </div>
                    <input type="text" className='form-control ' placeholder='filtrar'
                        value={Search}
                        onChange={async e => {
                            setSearch(e.target.value);

                            await request();
                        }}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-primary " onClick={() => toggleAddEdit(
                            {
                                id: uuidv4(),
                                organizationid: "",
                                name: "",

                                created: null,
                                deleted: null,
                            }, "add"
                        )}>
                            <i className="fa fa-plus" />

                        </button>
                    </div>
                </div>

            </div>

        </div>
        <div className='row mx-0'>
            <div className='col-12 col-md-8'>
                {
                    state.Records.length === 0 ?
                        <div className="row">
                            <div className='col-6 mx-auto text-center'>
                                <img src={emptyImg} className="img-fluid" style={{ maxWidth: "50%" }} alt="" />
                            </div>
                        </div>
                        : null
                }
                {
                    state.Records.length > 0 ?
                        <div className="card shadow-sm">


                            <div className="card-body  ">
                                {state.Records.length > 0 ?
                                    state.Records.map((item, index) => {
                                        let metterMetrics = item.metterHistories.filter(x => x.deleted === null);
                                        let amount = 0;
                                        let sales = 0;
                                        if (metterMetrics.length > 0) {
                                            amount = metterMetrics[0].amount;
                                            sales = metterMetrics[0].amount - metterMetrics[0].previousAmount;
                                        }
                                        return (
                                            <div className="row px-0 " key={index} >
                                                <div className="col clickable" onClick={() => toggleAddEdit(item, "update")}>


                                                    <span className="d-inline-block">
                                                        <label>
                                                            {item.name}
                                                        </label>

                                                    </span>

                                                </div>
                                                <div className='col'>

                                                    {FormatMoney(amount)}

                                                </div>

                                                <div className='col'>
                                                   {FormatMoney(sales)}
                                                </div>

                                                <div className="col-auto text-right">
                                                    <div className="btn-group">
                                                        <button className='btn btn-secondary btn-sm'
                                                            title="Historico"
                                                            onClick={() => toggleMetterHistory(item)}
                                                        >
                                                            <i className="fas fa-history"></i>
                                                        </button>
                                                        <button className='btn btn-primary btn-sm'
                                                            onClick={() => toggleAddEditHistory({
                                                                id: "",
                                                                metterId: item.id,
                                                                date: moment().format("YYYY-MM-DDT") + "08:00:00",
                                                                amount: null,
                                                                note: '',
                                                                dateEnd: moment().format("YYYY-MM-DDT") + "18:00:00",
                                                                amountEnd: null,
                                                                noteEnd: ''
                                                            }, "add", false)}
                                                        >
                                                            Agregar registro
                                                        </button>
                                                        <button className="btn btn-primary btn-sm"
                                                            title="Editar"
                                                            onClick={() => toggleAddEdit(item, "update", false)}
                                                        >
                                                            <i className="fa fa-edit" />
                                                        </button>
                                                        <button className="btn btn-primary btn-sm" onClick={() => deleteConfirm(item)} type="button">
                                                            <i className="fa fa-trash text-danger" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <hr className="my-1" />
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null}
                            </div>
                            <div className='card-footer overflow-hidden'>
                                <Pagination totalPages={state.TotalPages} currentPage={state.currentPage} onPageChanged={onPageChanged} />
                            </div>
                        </div >
                        : null
                }
            </div>
            <div className='col-12 col-md-4'>
                <TotalBilledMonthly />
            </div>
        </div>
    </>);
}

export default MetterList;