import { Card, Row, Col } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import API from '../../common/API';
import { epochToDateConverter, FormatMoney } from '../../common/helpers';

import moment from 'moment';
import Pagination from '../Common/Pagination';
import AddEditUser from './AddEditUser';


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Users = props => {

    const [state, setState] = useState({
        Records: [],
        totalPages: 0,
        totalRecords: 0,
        currentPage: 0,
        recordsPerPage: 50,
        From: null,
        Until: null,
        UI: {
            showAddEdit : false
        }
    })
    useEffect(() => {
        request();
    }, []);

    const request = async () => {
        let query = "";
        query += "Page=" + state.currentPage;
        query += "&Quantity=" + state.recordsPerPage;
        if (state.From !==null) {
            query += "&From=" + moment(state.From).unix();
        }

        if (state.Until !== null) {
            query += "&To=" + moment(state.Until).unix();
        }
        var request = await API.getAction("users", query);
        console.log("Response from api", request)
        if (request.data.status === "ok") {
            setState({
                ...state,
                Records: request.data.response,
                totalPages : request.data.quantityOfPages,
                totalRecords : request.data.quantityOfRecords
            })

        }
    }
 
    const onPageChanged = async obj => {
        setState({
            ...state,
            currentPage: obj
        })
        
        await request();
    }

    const toggle = async (obj, reload) => {
        setState({
            ...state,
            currentPage: obj,
            UI: {
                ...state.UI,
                SelectedItem : obj,
                showAddEdit : !state.UI.showAddEdit
            }
        })

        if (reload !== undefined) {
            request();
        }
    }
    const deleteConfirm = async (obj) => {
        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete ' + obj.firstName + "?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await API.getAction("Account/Delete?id=" + obj.id);
                        await request();
                    }
                },
                {
                    label: 'No',
                    //onClick: () => alert('Click No')
                }
            ]
        });
    }


    return (<>
        {state.UI.showAddEdit ?
            <AddEditUser toggle={toggle} show={state.UI.showAddEdit} model={state.UI.SelectedItem} />
            : null}
        <Row>
            <Col xs={12}>
                <Card className="shadow-sm">
                    <Card.Header className="row mx-0 p-1">
                        <div className="col">
                            <Card.Title>
                               Users

                            </Card.Title>
                        </div>
                        <div className="col-4 text-right">
                                         <button className="btn btn-sm btn-primary" onClick={()=>toggle()}>
                                    <i className="fa fa-plus"></i>
                                </button>
                        </div>
                    </Card.Header>
                    <Card.Body className="table-responsive p-1">
                        {state.Records.length > 0 ?
                            <table className="table table-stripped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.Records.map((item, index) => {

                                        let itemParsed = item;
                                        return (<tr key={index}>
                                            <td>{state.currentPage === 0 ? index + 1 : (index + 1) * (state.currentPage + 1)}</td>
                                            <td>{item.firstName} {item.lastName}</td>
                                            <td>{item.email}</td>
                                            <td className="text-right">
                                                <div className="btn-group">
                                                    <button className="btn btn-primary btn-sm" onClick={() => toggle(itemParsed)}>
                                                        <i className="fa fa-edit" />
                                                    </button>
                                                    <button className="btn btn-primary btn-sm" onClick={() => deleteConfirm(itemParsed)}>
                                                        <i className="fa fa-trash text-danger" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                            : null}
                    </Card.Body>
                    <Card.Footer>
                        <Pagination totalPages={state.totalPages} currentPage={state.currentPage} onPageChanged={onPageChanged} />
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    </>);
}

export default Users;