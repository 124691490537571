import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Profile3 from "../../assets/img/team/profile-picture-3.jpg";

const Profile = props => {
  return (<>
    <Col xs={12} xl={4}>
      <Row>
        <Col xs={12}>

        </Col>
        <Col xs={12}>

        </Col>
      </Row>
    </Col>
  </>);
}

export default Profile;