import React, { useState } from 'react'
import { Routes } from '../../routes';
import ProviderList from './ProviderList';
const Index = props => {
    return (
        <div className="row mx-0">
            <nav aria-label="breadcrumb" className="mb-2">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/"><i className="fa fa-home"></i></a></li>

                    <li className="breadcrumb-item"><a href={Routes.ExpensesDashboard}>Proveedores</a></li>
                    
                </ol>
            </nav>
            <div className="col-12">
                <ProviderList Full/>
            </div>
        </div>
    )
}
export default Index;