import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from 'react-bootstrap';

import * as yup from 'yup';
import { Formik, useFormik } from 'formik';


import moment from 'moment';
import API from '../../common/API';

import { v4 as uuidv4 } from 'uuid';
import { parsedUser } from '../../common/GetCurrentUser';


//Validation
const validationSchema = yup.object({
    //   id: yup.string().required(),
    name: yup.string().required(),
    //    organizationId: yup.string(),

    price: yup.number().required()


});
//Component
const AddEditService = props => {
    const [state, setState] = useState({
        UI: {
            Validated: false
        },

    })
    const [currencies, setCurrencies] = useState([]);
    const [dataTaxes, setDataTaxes] = useState([]);
    const user = parsedUser();
    const requestTaxesData = async () => {

        

        //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
        let requestAPI = await API.getAction(`Taxes/${user.organizationId}`);

        if (requestAPI.data.status === "ok") {
            setDataTaxes(requestAPI.data.response)
        }
    };
    const requestCurrencies = async () => {

        

        //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
        let requestAPI = await API.getAction(`Currencies/${user.organizationId}`);

        if (requestAPI.data.status === "ok") {
            setCurrencies(requestAPI.data.response)
        }
    };
    const handleSubmit = async (obj, event) => {



        console.log("values to submit", obj);

        if (obj.id.length === 0) {
            obj.id = uuidv4();
            obj.created = moment().format("YYYY-MM-DD HH:mm:ss")

            
        }
        obj.organizationid = user.organizationId;
        if (obj.identifier === null) {
            obj.identifier = uuidv4();
        }


        let request = await API.postAction(`services/${props.mode}`, obj);

        console.log("response from adding", request);
        if (request.status === 200) {
            props.toggle(null,props.mode, true);
        }
    };
    const formik = useFormik({
        initialValues: props.model,
        validationSchema,
        onSubmit: handleSubmit,

    });

    const AddEditTaxes=item=>{
        let serviceTasexOnModel = formik.values.serviceTaxes;
        var exist = serviceTasexOnModel.filter(x=>x.taxesId=== item.id && x.deleted === null )[0];
        if(exist){
            //means we are deleting
            serviceTasexOnModel.filter(x=>x.taxesId=== item.id && x.deleted === null )[0].deleted = moment().format();
        }else{
            serviceTasexOnModel.push({
                id: uuidv4(),
                created : moment().format(),
                serviceId : formik.values.id,
                taxesId: item.id
            })
        }

        formik.setFieldValue("serviceTaxes", serviceTasexOnModel );
    }
    useEffect(() => {
        requestCurrencies();
        requestTaxesData();
        setTimeout(() => {
            var focus = document.getElementsByName("name")[0];
            if (focus) {
                focus.focus();
            }
        }, 500)
    }, [])
if(!props.model){
    return(<></>)
}
    return (
        <Modal show={props.show} onHide={props.toggle} size='md' className='modalRight'>
            <Modal.Header closeButton>
                <Modal.Title>Servicio</Modal.Title>

            </Modal.Header>



            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {/* 
                LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING
                {Object.values(formik.errors).map((item, index)=>{
                    return(item);
                })} */}
                    <Row>
                        <Col xs={12} >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text"
                                name="name"
                                value={formik.values.name ?? ""} onChange={formik.handleChange} />
                            {formik.errors.name ? <div className="invalid text-sm">{formik.errors.name}</div> : null}
                        </Col>
                        <Col xs={12}>
                            <Form.Label>Descripción</Form.Label>
                            <textarea value={formik.values.description} rows={4}
                                className="form-control"
                                onChange={e => {
                                    formik.setFieldValue("description", e.target.value)
                                }}></textarea>
                            {formik.errors.description ? <div className="invalid text-sm">{formik.errors.description}</div> : null}
                        </Col>
                        <Col xs={12} md={6} className="mt-2">
                            <Form.Label>Moneda</Form.Label>
                            <select className='form-control'
                            name="currencyid"
                                value={formik.values.currencyid}
                                onChange={formik.handleChange}>
                                <option value="">-</option>
                                {currencies && currencies.map((item, index) => {
                                    return (<option value={item.id} key={index}>{item.name}</option>)
                                })}
                            </select>
                            {formik.errors.currencyid ? <div className="invalid text-sm">{formik.errors.currencyid}</div> : null}
                        </Col>

                        <Col xs={12} md={6} className="mt-2">
                            <Form.Label>Costo</Form.Label>
                            <Form.Control type="text"
                                placeholder=''
                                name="price"
                                value={formik.values.price ?? ""} onChange={formik.handleChange} />
                            {formik.errors.price ? <div className="invalid text-sm">{formik.errors.price}</div> : null}
                        </Col>
                        <Col xs={12} className="mt-3">
                            <Form.Label>Impuestos</Form.Label>
                            {dataTaxes && dataTaxes.map((item, index) => {
                                let current = item;
                                let exist = props.model.serviceTaxes && props.model.serviceTaxes.filter(x => x.taxesId === item.id && x.deleted === null)[0];
                                if (exist) {
                                    return (<p key={index}>
                                        <input type="checkbox" checked value={item.id} onClick={()=>AddEditTaxes(current)} id={"tax_"+item.id} />
                                        <label htmlFor={"tax_"+item.id} className="mx-2">{item.Name} {item.percentage} %</label>
                                    </p>)
                                } else {
                                    return (<p key={index}>
                                        <input type="checkbox" value={current.id} onClick={()=>AddEditTaxes(current)} id={"tax_"+item.id} />
                                        <label htmlFor={"tax_"+item.id} className="mx-2">{item.Name} {item.percentage} %</label>
                                    </p>)

                                }
                            })}



                            {formik.errors.email ? <div className="invalid text-sm">{formik.errors.email}</div> : null}
                        </Col>


                    </Row>

                </Modal.Body>
                <Modal.Footer className='p-1'>
                    <Button variant="primary" type="submit" className="btn-sm"
                        disabled={state.UI.isLoading}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    )
}

export default AddEditService;