import React, { useEffect } from 'react';

import { Modal, Button, Col, Row, Form } from 'react-bootstrap';
import { useState } from '@hookstate/core';
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';


import moment from 'moment';
import API from '../../common/API';
import { v4 as uuidv4 } from 'uuid';
import { parsedUser } from '../../common/GetCurrentUser';
//Validation
const validationSchema = yup.object({
    name: yup.string().required("campo requrido")

});
//Component
const AddEdit = props => {
    const state = useState({
        UI: {
            Validated: false
        },
    })

    const user = parsedUser();

    const handleSubmit = async (obj, event) => {


        let path = "providers/Add";
        if (obj.id.length === 0) {
            obj.id = uuidv4();
            obj.created = moment().format("YYYY-MM-DDTHH:mm:ss")
        } else {
            path = "providers/update"
        }
        obj.organizationid=user.organizationId;
debugger
        let request = await API.postAction(path, obj);
        
        console.log("response from adding", request);
        if (request.status === 200) {
            props.toggle(request.data.response, true);
        }
    };
    const formik = useFormik({
        initialValues: props.model ? JSON.parse(JSON.stringify(props.model)) :
            {
                name: "",
                created: new Date(),
                id: "", organizationid: "",
                dgiiequivalentCode: "",
                identifier: ''
            },
        validationSchema,
        onSubmit: handleSubmit,
    });

   

    useEffect(() => {
        //   loadDepartments();
    }, [])

    return (
        <Modal show={props.show} onHide={props.toggle} size='md'>
            <Modal.Header closeButton>
                <Modal.Title>Proveedor</Modal.Title>
            </Modal.Header>



            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>

                    <Row>
                        <Col xs={12}>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text"
                                name="name"
                                value={formik.values.name} onChange={formik.handleChange} />
                            {formik.errors.name ? <div className="invalid text-sm">{formik.errors.name}</div> : null}
                        </Col>
                        <Col xs={12}>
                            <Form.Label>Identificador</Form.Label>
                            <Form.Control type="text"
                                name="identifier"
                                value={formik.values.identifier} onChange={formik.handleChange} />
                            {formik.errors.identifier ? <div className="invalid text-sm">{formik.errors.identifier}</div> : null}
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" className="btn-sm"
                        disabled={state.UI.isLoading.value}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    )
}

export default AddEdit;