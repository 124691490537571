import React, { useEffect, useState } from "react";
import { parsedUser } from "../../common/GetCurrentUser";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import API from "../../common/API";
import moment from "moment";
import { FormatMoney } from "../../common/helpers";
import { Col, Row } from "react-bootstrap";



const TotalSaleMonthly = props => {

    const [from, setFrom] = useState(moment().add('month', -12).format('YYYY-MM-DD'));
    const [to, setTo] = useState(moment().format('YYYY-MM-DD'));
    const [state, setState] = useState(null);
    const [records, setRecords] = useState([]);
    const user = parsedUser();
    const bindDataRemote = async () => {

        let query = `organizationId=${user.OrganizationId}&from=${from}&to=${to}`;
        const request = await API.getAction(`tank/TotalSaleMonthly?${query}`);

        console.log(request);


        setRecords(request.data.response);
    }

    useEffect(() => {
        if (records) {
            const labels = records.map(item =>moment(item.date).format("MMM, YYYY"));
            setState(
                {
                    //  [],
                    labels,
                    datasets: [
                        {
                            label: 'GL',
                            data: records.map(item => item.total),
                            backgroundColor: 'red',
                        },


                    ],
                }
            )
        }
    }, [records])


    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    )

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
                text: "Total Purchased",
            },
        },
    }
    useEffect(() => {
        bindDataRemote();
    }, [])

    useEffect(() => {
        bindDataRemote();
    }, [from, to])

    if (!state || !records) {
        return (<></>)
    }

    return (<>
        <div className="card shadow-sm mb-2">
            <div className="card-header">
                <Row>
                    <Col>
                        <span className="display-6 d-inline text-uppercase">Entrega Mensual</span>
                    </Col>
                    <Col className="text-end">
                        <div className="input-group">
                            <input type="date" value={from} onChange={e => setFrom(e.target.value)}  className="form-control"/>
                            <input type="date" value={to} onChange={e => setTo(e.target.value)}  className="form-control"/>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="card-body">
                {/* <Bar options={options} data={records} /> */}
                <Line options={options} data={state} />
            </div>
            <div className="card-footer">
            <h4>Total: {FormatMoney(records.reduce((a, b) => a + b.total, 0))} gl</h4>
                </div>
        </div>
    </>)
}

export default TotalSaleMonthly;