
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import API from '../../common/API';
import { parsedUser } from '../../common/GetCurrentUser';

import { Routes } from '../../routes';
import AddEdit from './AddEdit';
import emptyImg from "../../assets/img/illustrations/no_content.svg";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Pagination from '../Common/Pagination';


import { v4 as uuidv4 } from 'uuid';

const CustomerList = props => {
    const history = useHistory();
    const [Search, setSearch] = useState("");
    const [state, setState] = useState({
        Customers: [],
        UI: {
            IsLoading: false,
            showAddEdit: false,
            Customerselected: null
        },
        currentPage: 0,
        Quantity: 100,
        TotalPages: 0,
        OnlyPendingToPaid: false
    })
    const theUser = parsedUser();
    useEffect(() => {
        if (theUser === null) {
            window.location = Routes.SignIn.path;
        }
        request();
    }, [state.currentPage]);


    const onPageChanged = async obj => {
        setState({
            ...state,
            currentPage: obj
        });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }
    const request = async () => {

        let Query = "search=" + Search + "&quantity=" + state.Quantity + "&page=" + state.currentPage+"&OrganizationId="+theUser.OrganizationId;

        //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
        let requestAPI = await API.getAction(`Customers/list` , Query);

        if (requestAPI.data.status === "ok") {
            setState({
                ...state,
                Customers: requestAPI.data.response,
                //   Quantity: requestAPI.data.quantityOfRecords,
                //     Page: requestAPI.data.page,
                TotalPages: requestAPI.data.totalPages

            })
        }
    };

    const [showAddEdit, setShowAddEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const toggleAddEdit = async (obj, mode, reload) => {
        setMode(mode);
        setSelectedItem(obj);
        setShowAddEdit(!showAddEdit);
        if (reload) {
            await request();
        }
    }

    const deleteConfirm = async (obj) => {

        let currentUser = parsedUser();

        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete ' + obj.name + "?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await API.getAction("customers/delete/" + obj.id);
                        await request();
                    }
                },
                {
                    label: 'No',
                    //     onClick: () => alert('Click No')
                }
            ]
        });
    }
    const [mode, setMode] = useState("add");
    return (<>
        {showAddEdit ?
            <AddEdit toggle={toggleAddEdit} show={showAddEdit} model={selectedItem} mode={mode} /> : null}


        <div className="row mb-2 mx-0">
            <div className="col ">
                <h5 className="display-4">
                    <i className="fas fa-users"></i>&nbsp;Clientes</h5>
            </div>
            <div className="ml-auto col-auto text-right">
                <div className='input-group'>
                    <div className='input-group-text'>
                        <i className='fa fa-search'></i>
                    </div>
                    <input type="text" className='form-control ' placeholder='filtrar'
                        value={Search}
                        onChange={async e => {
                            setSearch(e.target.value);

                            await request();
                        }}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-primary " onClick={() => toggleAddEdit(
                            {
                                Id: uuidv4(),
                                OrganizationId: "",
                                Created: null,
                                Updated: null,
                                Name: '',
                                Identificator: '',
                                Deleted: null,
                                Phone: null,
                                Email: null,
                                Address1: null,
                                Address2: null,
                                City: null,
                                Country: null
                            }, "add"
                        )}>
                            <i className="fa fa-plus" />

                        </button>
                    </div>
                </div>

            </div>
        </div>
        {
            state.Customers.length === 0 ?
                <div className="row">
                    <div className='col-6 mx-auto text-center'>
                        <img src={emptyImg} className="img-fluid" style={{ maxWidth: "50%" }} alt="" />
                    </div>
                </div>
                : null
        }
        {
            state.Customers.length > 0 ?
                <div className='row mx-0'>
                    <div className='col-12'>
                        <div className="card shadow-sm">
                            <div className="card-body  ">
                                {state.Customers.length > 0 ?
                                    state.Customers.map((item, index) => {
console.log(item);
                                        return (
                                            <div className="row px-0 " key={index} >
                                                <div className='col-auto'>
                                                    {state.currentPage > 0 ? (state.currentPage * state.Quantity) + index + 1 : index + 1}
                                                </div>

                                                <div className="col " >


                                                    <span className="d-inline-block">
                                                        <label>
                                                            <button className='btn btn-link'
                                                                onClick={() => {
                                                                    history.push(Routes.CustomerDetails.clean + item.id)
                                                                }}
                                                                type="button">


{item.identificator} - {item.name}
                                                            </button>
                                                        </label>
                                                        <br />
                                                        <small className="text-muted">{item.phone} {item.email}</small>
                                                    </span>

                                                </div>

                                                <div className="col-auto text-right">
                                                    <div className="btn-group">

                                                        <button className="btn btn-primary btn-sm"
                                                            onClick={() => toggleAddEdit(item, "update", false)}
                                                        >
                                                            <i className="fa fa-edit" />
                                                        </button>
                                                        <button className="btn btn-primary btn-sm" onClick={() => deleteConfirm(item)} type="button">
                                                            <i className="fa fa-trash text-danger" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <hr className="my-1" />
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null}
                            </div>
                            <div className='card-footer overflow-hidden'>
                                <Pagination totalPages={state.TotalPages} currentPage={state.currentPage} onPageChanged={onPageChanged} />
                            </div>
                        </div >
                    </div>
                </div>
                : null
        }
    </>);
}

export default CustomerList;