import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import CRUD from "../core/genericCRUD/CRUD";
import { parsedUser } from "../../common/GetCurrentUser";

const ClientComponent = (props: any) => {
  const [t] = useTranslation("global");
  const user = parsedUser();
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("clients") ?? ""}
      getRecordsPath={`customers/list?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath={`customers/AddEdit`}
      deleteRecordPath="customers/Delete"
      fieldsList={[
        {
          name: "customerTypeName",
          title: t("Customer Type"),
        },
        {
          name: "identificator",
          title: t("Identificator"),
        },
        {
          name: "name",
          title: t("name"),
        },
      ]}
      filterFields={[
        {
          name: "search",
          htmlType: "text",
          dbType: "string",
          title: t("search") ?? "",
          containerClass: "col-12 col-md-6",
          required: false,
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "identificator",
          htmlType: "text",
          dbType: "string",
          title: t("identificator") ?? "",
          containerClass: "col-12 col-md-6",
          required: false,
        },
        {
          name: "customerTypeId",
          htmlType: "select",
          dbType: "string",
          title: t("Customer Type") ?? "",
          containerClass: "col-12 col-md-6",
          required: false,

          dataSourceTextField: "name",
          dataSourceValueField: "id",
          dataSource: "customerType/list?OrganizationId=" + user.OrganizationId,
        },

        {
          name: "organizationId",
          htmlType: "hidden",
          dbType: "string",
          //title: t("value")??"",
          containerClass: "col-6",
          required: true,
          value: user.OrganizationId,
        },
      ]}
    />
  );
};

export default ClientComponent;
