import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form } from 'react-bootstrap';

import * as yup from 'yup';


import moment from 'moment';
import API from '../../common/API';

import { v4 as uuidv4 } from 'uuid';
import { parsedUser } from '../../common/GetCurrentUser';
import { FormatMoney } from '../../common/helpers';
import { confirmAlert } from 'react-confirm-alert';
import AddEditMetterHistory from './AddEditMetterHistory';


//Validation
const validationSchema = yup.object({
    //   id: yup.string().required(),
    amount: yup.number().required(),
    date: yup.string().required(),

});
//Component
const MetterHistory = props => {
    const [state, setState] = useState({
        UI: {
            Validated: false
        },

    })
    const [showAddEdit, setShowAddEdit] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [records, setRecords] = useState([]);
    const [mode, setMode] = useState("add");

    const user = parsedUser();

    const bindRemote = async () => {

        let request = await API.getAction(`metters/history/${props.model.id}`);
        if (request.data.status === "ok") {
            setRecords(request.data.response);
        }

    }
    const toggleAddEdit = async (obj, mode, reload) => {
        setMode(mode);
        setSelectedItem(obj);
        setShowAddEdit(!showAddEdit);
        if (reload) {
            await bindRemote();
        }
    }

    const deleteConfirm = async (obj) => {



        confirmAlert({
            title: 'Confirm',
            message: 'Are you sure to delete this record?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        await API.getAction("Metters/history/delete/" + obj.id + "?UserId=" + user.ID);
                        await bindRemote();
                    }
                },
                {
                    label: 'No',
                    //     onClick: () => alert('Click No')
                }
            ]
        });
    }

    useEffect(() => {
        bindRemote();
    }, [])
    if (!props.model) {
        return (<></>)
    }
    let totalSale = 0;
    return (
        <>
            {showAddEdit ?
                <AddEditMetterHistory toggle={toggleAddEdit} show={showAddEdit} model={selectedItem} mode={mode} /> : null}

            <Modal show={props.show} onHide={props.toggle} size='lg' className='modalRight'>
                <Modal.Header closeButton>
                    <Modal.Title>Historico <small>{props.model.name}</small></Modal.Title>
                    <button className='btn btn-sm btn-primary mx-2'
                        onClick={() => toggleAddEdit({
                            id: "",
                            metterId: props.model.id,
                            date: moment().format("YYYY-MM-DDT") + "08:00:00",
                            amount: null,
                            note: '',
                            previousDate: null,
                            previousAmount: null,
                        }, "add", false)}
                    >
                        <i className='fa fa-plus'></i>
                    </button>
                </Modal.Header>


                <Modal.Body>
                    <Row className='mx-0'>
                        <Col xs={12}>
                            <table className='table table-responsive'>
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Anterior</th>
                                        <th>Valor</th>
                                        
                                        <th>Venta</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records && records.map((item, index) => {
                                        let sale =   item.amount-item.previousAmount;
                                        totalSale = totalSale + sale;
                                        return (<tr key={index}>
                                            <td>{moment(item.date).format("MMM DD, YYYY hh:mm a")}</td>
                                            <td>{FormatMoney( (item.previousAmount ?? 0))}</td>
                                            <td>{FormatMoney(item.amount)}</td>
                                            
                                            <td>{FormatMoney(sale)}</td>
                                            <td className='text-right'>
                                                <div className="btn-group">

                                                    <button className="btn btn-primary btn-sm"
                                                        title="Editar"
                                                        onClick={() => toggleAddEdit(item, "update", false)}
                                                    >
                                                        <i className="fa fa-edit" />
                                                    </button>
                                                    <button className="btn btn-primary btn-sm" onClick={() => deleteConfirm(item)} type="button">
                                                        <i className="fa fa-trash text-danger" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>)
                                    })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={4}>
                                            <h4>Total Venta: {FormatMoney(totalSale)} gl</h4>
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                        </Col>
                    </Row>

                </Modal.Body>


            </Modal>
        </>
    )
}

export default MetterHistory;