import React, { useCallback, useEffect, useRef, useState } from "react"

import { useTranslation } from "react-i18next"
import API from "../../common/API"

const FieldSelector = (props: any) => {
  const { t } = useTranslation("global")
  const [records, setRecords] = useState([])

  const model = props.model

 

  const requestRecords = async () => {
    let query = ""
    if (model.dependOf && props.obj) {
      query = `${model.dependOf}=${props.obj[model.dependOf]}`
    }
    let requestAPI: any = await API.getAction(
      model.dataSource + (query.length > 0 ? "?" + query : "")
    )

    if (requestAPI.data.status === "ok") {
      setRecords(requestAPI.data.response)
    }
  }


  const returnField = () => {
    console.log(model)
    switch (model.htmlType) {
      case "email":
      case "text":
      case "date":
      case "file":
      case "number":
      case "password":
      case "color":
        return (
          <input
            type={model.htmlType}
            className={"form-control"}
            name={model.name}
            value={props.value ?? ""}
            onChange={e => {
              console.log(e.target.value)
              props.updateField(model.name, e.target.value)
            }}
          />
        )

      case "textarea":
        return (
          <textarea
            className={"form-control"}
            name={model.name}
            value={props.value ?? ""}
            onChange={e => {
              console.log(e.target.value)
              props.updateField(model.name, e.target.value)
            }}
          ></textarea>
        )

      case "select":
        return (
          <select
            className={`select form-control`}
            name={model.name}
            value={props.value ?? ""}
            onChange={e => {
              props.updateField(model.name, e.target.value)
            }}
          >
            <option value="">-</option>
            {records &&
              records.map((item, index) => {
                console.log(item)
                return (
                  <option key={index} value={item[model.dataSourceValueField]}>
                    {item[model.dataSourceTextField]}
                  </option>
                )
              })}
          </select>
        )

      case "bool":
        return (
          <button
            className={
              "btn " + (props.value ? "btn-primary" : "btn-outline-primary")
            }
            onClick={e => {
              props.updateField(model.name, !props.value)
            }}
          >
            {props.value ? (
              <i className="fa-solid fa-toggle-on "></i>
            ) : (
              <i className="fa-solid fa-toggle-off"></i>
            )}
          </button>
        )
        case "label":
          return(<label>{props.value}</label>)

      default:
        return <></>
    }
  }

  // <select
  //   className={`select w-full`}
  //   name={model.name}
  //   value={props.value ?? ""}
  //   onChange={(e) => {
  //     props.updateField(model.name, e.target.value);
  //   }}
  // >
  //   <option value="">-</option>
  //   {records &&
  //     records.map((item, index) => {
  //       console.log(item);

  //       return (
  //         <option key={index} value={item[model.dataSourceValueField]}>
  //           {item[model.dataSourceTextField]}
  //         </option>
  //       );
  //     })}
  // </select>;
  //debugger;


  useEffect(() => {
    console.log(model)
    if (model && model.dataSource && !model.dataSourceLocal) {
      requestRecords()
    } else if (model && model.dataSourceLocal) {
      console.log(model.dataSourceLocal)
      setRecords(model.dataSourceLocal)
    }
  }, [model])

  useEffect(() => {
    if (props.obj) {
      if (model && model.dataSource && !model.dataSourceLocal) {
        requestRecords()
      }
    }
  }, [props.obj])

  if (!model) {
    return <></>
  }


  
  return (
    <div className={model.containerClass + " my-2"}>
      <label>
        {t(model.title)}
        {model.required && <i className="text-danger">*</i>}
      </label>
      <br />
      {returnField()}
      {props.errors && props.errors[model.name] ? (
        <div className="invalid text-sm text-danger">
          {props.errors[model.name]}
        </div>
      ) : null}
    </div>
  )
}

export default FieldSelector
