import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Routes } from "../../routes";
import { parsedUser } from "../../common/GetCurrentUser";

import QuickAddEditMetter from "../Metters/QuickAddEditMetter";
import moment from "moment";
import QuickAddEditVolume from "../ProductVolumes/QuickAddEditVolume";
import QuickAddEditSale from "../SalesByGallonPerDay/QuickAddEditSale";
import QuickAddEditCustomerSale from "../Customers/QuickAddEditCustomerSale";
import QuickAddEditCustomerSaleGranel from "../Customers/QuickAddEditCustomerSaleGranel";
import CalibrationTable from "../Metters/CalibrationTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Summary from "./Summary";
import TotalPurchaseMonthly from "./TotalPurchaseMonthly";
import TotalSaleMonthly from "./TotalSaleMonthly";
import TotalPurchaseYearly from "./TotalPurchaseYearly";
import TotalSaleYearly from "./TotalSaleYearly";
import TotalSaleAndDelivered from "./TotalSaleAndDelivered";
import TotalManualSaleDaily from "./TotalManualSaleDaily";

const DashboardPageContainer = () => {
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const user = parsedUser();
  const { id } = useParams();
  const history = useHistory();
  const [totalMeters, setTotalMeters] = useState(0);
  const SumMeter = (obj) => {
    let count = 0;
    Array.isArray(obj) &&
      obj.forEach((item) => {
        let amount = item.amount - item.previousAmount;
        count += amount;
      });

    setTotalMeters(count);
  };
  const [showCalibration, setShowCalibration] = useState(false);
  const toggleCalibration = () => {
    setShowCalibration(!showCalibration);
  };

  useEffect(() => {
    if (!user) {
      history.push(Routes.SignIn.path);
    }
  }, []);

  return (
    <Container>
      <Row className="mx-0">
        <Col xs={12} md={6}>
          <TotalPurchaseYearly />
          <div className="my-2"></div>
          <TotalPurchaseMonthly />
          <div className="my-2"></div>
          <TotalManualSaleDaily />
        </Col>

        <Col xs={12} md={6}>
          <TotalSaleYearly />
          <div className="my-2"></div>

          <TotalSaleMonthly />
        </Col>
       
      </Row>
    </Container>
  );
};

export default DashboardPageContainer;
