import React, { useEffect, useState } from 'react';

import { Modal, Button, Col, Row, Form, Tab, Tabs } from 'react-bootstrap';

import API from '../../common/API';
import { parsedUser } from '../../common/GetCurrentUser';
import { FormatMoney, NumberFormat } from '../../common/helpers';



//Component
const CalibrationTable = props => {
    const [calibrations, setCalibrations] = useState({
        metter8000: [],
        metter30000: []
    });


    const requestCalibration = async (item) => {
        let request8000 = await API.getAction(`tank/calibrationTable?id=1`);
        let request30000 = await API.getAction(`tank/calibrationTable?id=2`);
        
        setCalibrations({
            metter8000: request8000.data.response,
            metter30000: request30000.data.response
        })
        console.log(request8000)
    }

    useEffect(() => {
        requestCalibration();
    }, [])

    return (
        <Modal show={props.show} onHide={props.toggle} size='md' className='modalRight'>
            <Modal.Header closeButton>
                <Modal.Title>Tablas de Calibración</Modal.Title>

            </Modal.Header>


            <Modal.Body>
                {/* 
                LEAVE IT HERE SO WE CAN PROCCEED TO TROUBLESHOOT ANYTHING
                {Object.values(formik.errors).map((item, index)=>{
                    return(item);
                })} */}
                <Row>
                    <Col xs={12} >
                        <Tabs>
                            <Tab.Pane eventKey="metter8000"
                                title="TANQUE 8,000">
                                <div className='table-responsive'>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Percentage</th>
                                                <th>Vol. Cal ( gls )</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {calibrations.metter8000.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.percentage} %</td>
                                                        <td>{NumberFormat(item.volume)}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="metter30000"
                                title="TANQUE 30,000">
                                <div className='table-responsive'>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Percentage</th>
                                                <th>Volume</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {calibrations.metter30000.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.percentage} %</td>
                                                        <td>{NumberFormat(item.volume)}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Tab.Pane>
                        </Tabs>
                    </Col>


                </Row>

            </Modal.Body>


        </Modal>
    )
}

export default CalibrationTable;