import React, { useEffect, useState } from "react";
import { parsedUser } from "../../common/GetCurrentUser";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import API from "../../common/API";
import moment from "moment";
import { FormatMoney } from "../../common/helpers";



const TotalBilledMonthly = props => {

    const [state, setState] = useState(null);
    const [records, setRecords] = useState([]);
    const user = parsedUser();
    const request = async () => {

        let Query = "OrganizationId=" + user.OrganizationId;

        let dataMockup = {data: {
            "status": "ok",
            "response": [
                {
                    "month": "2023-01",
                    "amountBilled": 6,
                    "totalBilled": 97661.1,
                    "amountPendingToBePaid": 6,
                    "totalPendingToBePaid": 97661.1,
                    "amountTotalPaid": 0,
                    "totalPaid": 0
                },
                {
                    "month": "2022-12",
                    "amountBilled": 7,
                    "totalBilled": 96182.41,
                    "amountPendingToBePaid": 6,
                    "totalPendingToBePaid": 85937.53,
                    "amountTotalPaid": 1,
                    "totalPaid": 10244.88
                },
                {
                    "month": "2022-11",
                    "amountBilled": 9,
                    "totalBilled": 564590.23,
                    "amountPendingToBePaid": 4,
                    "totalPendingToBePaid": 538664.16,
                    "amountTotalPaid": 5,
                    "totalPaid": 25926.07
                },
                {
                    "month": "2022-10",
                    "amountBilled": 9,
                    "totalBilled": 130986.11,
                    "amountPendingToBePaid": 0,
                    "totalPendingToBePaid": 0,
                    "amountTotalPaid": 9,
                    "totalPaid": 130986.11
                },
                {
                    "month": "2022-09",
                    "amountBilled": 7,
                    "totalBilled": 123341.05,
                    "amountPendingToBePaid": 0,
                    "totalPendingToBePaid": 0,
                    "amountTotalPaid": 7,
                    "totalPaid": 123341.05
                },
                {
                    "month": "2022-08",
                    "amountBilled": 9,
                    "totalBilled": 182770.33,
                    "amountPendingToBePaid": 1,
                    "totalPendingToBePaid": 10,
                    "amountTotalPaid": 8,
                    "totalPaid": 182760.33
                },
                {
                    "month": "2022-07",
                    "amountBilled": 6,
                    "totalBilled": 70971.65,
                    "amountPendingToBePaid": 1,
                    "totalPendingToBePaid": 10,
                    "amountTotalPaid": 5,
                    "totalPaid": 70961.65
                },
                {
                    "month": "2022-06",
                    "amountBilled": 8,
                    "totalBilled": 152391.65,
                    "amountPendingToBePaid": 3,
                    "totalPendingToBePaid": 60591.2,
                    "amountTotalPaid": 5,
                    "totalPaid": 91800.45
                },
                {
                    "month": "2022-05",
                    "amountBilled": 12,
                    "totalBilled": 557485.65,
                    "amountPendingToBePaid": 5,
                    "totalPendingToBePaid": 415724,
                    "amountTotalPaid": 7,
                    "totalPaid": 141761.65
                },
                {
                    "month": "2022-04",
                    "amountBilled": 7,
                    "totalBilled": 106371.65,
                    "amountPendingToBePaid": 2,
                    "totalPendingToBePaid": 9851.2,
                    "amountTotalPaid": 5,
                    "totalPaid": 96520.45
                },
                {
                    "month": "2022-03",
                    "amountBilled": 12,
                    "totalBilled": 180168.4,
                    "amountPendingToBePaid": 4,
                    "totalPendingToBePaid": 10011.2,
                    "amountTotalPaid": 8,
                    "totalPaid": 170157.2
                },
                {
                    "month": "2022-02",
                    "amountBilled": 10,
                    "totalBilled": 45438.78,
                    "amountPendingToBePaid": 2,
                    "totalPendingToBePaid": 458.5,
                    "amountTotalPaid": 8,
                    "totalPaid": 44980.28
                },
                {
                    "month": "2022-01",
                    "amountBilled": 18,
                    "totalBilled": 72943.6,
                    "amountPendingToBePaid": 7,
                    "totalPendingToBePaid": 7844.56,
                    "amountTotalPaid": 11,
                    "totalPaid": 65099.04
                }
            ],
            "quantityOfPages": 1,
            "quantityOfRecords": 13,
            "page": 0,
            "quantityPerPage": 0,
            "summary": null
        }}
        //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
        let requestAPI =dataMockup;// await API.getAction("invoice/TotalBilledMonthly", Query);

        if (requestAPI.data.status === "ok") {
            var result = requestAPI.data.response;
            setRecords(result);
            // {
            //     labels,
            //     datasets: [
            //       {
            //         label: 'Dataset 1',
            //         data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            //         backgroundColor: 'rgba(255, 99, 132, 0.5)',
            //       },

            //     ],
            //   };
            let labels = [];

            result.forEach(item => {
                labels.push(moment(item.month).format("MMM YYYY"));
            })
            setState(
                {
                    labels,
                    datasets: [
                        {
                            label: 'Total facturado',
                            data: result.map(x => x.totalBilled),
                            backgroundColor: '#4081d0',
                        },
                        {
                            label: 'Pendiente de pago',
                            data: result.map(x => x.totalPendingToBePaid),
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                            label: 'Total Cobrado',
                            data: result.map(x => x.totalPaid),
                            backgroundColor: '#6dc77e',
                        },

                    ],
                }
            )
        }
    };
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Total facturado por mes',
            },
        },
    };
    useEffect(() => {
        request();
    }, [])

    if (!state) {
        return (<></>)
    }

    return (<>
        <div className="card shadow-sm mb-2">
            <div className="card-header">
                <span className="display-6">Total facturado por mes</span>
            </div>
            <div className="card-body">
                <Bar options={options} data={state} />
                {/* <table className="table">
            <thead>
                <tr>
                    <th>Mes</th>
                    <th>Cantidad</th>
                    <th>Total</th>
                </tr>
            </thead>
<tbody>
    {records.map((item, index)=>{
        return (<tr key={index}>
            <td>{moment(item.month).format("MMM YYYY")}</td>
            <td>{item.amount}</td>
            <td className="text-right">{FormatMoney( item.totalValue)}</td>
        </tr>)
    })}
</tbody>
        </table> */}
            </div>
        </div>
    </>)
}

export default TotalBilledMonthly;