
import React from 'react';
import { Image } from 'react-bootstrap';


import logoAvocado from '../assets/img/logo_zaboka_avocado.png';
export default (props) => {

  const { show } = props;

  return (
    <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
      <Image className="loader-element animate__animated animate__jackInTheBox" src={logoAvocado} style={{height:"40px"}} />
    </div>
  );
};
